import React, { ReactElement } from 'react'

import { Divider, Stack } from '@mui/material'

import { useIsMobile } from '../../../../shared/hooks/is-mobile'
import PaymentMethodsLogo from './assets/footer_paymentmethod.webp'
import { ConsentBanner } from './components/ConsentBanner'
import { Content } from './components/Content'
import { Copyright } from './components/Copyright'
import { MT4Platform } from './components/MT4Platform'
import { RegulationCards } from './components/RegulationCards'
import { SocialMediaLinks } from './components/SocialMediaLinks'
import { FooterContainer, PaymentContainer, PaymentImage } from './styled'

export function Footer(): ReactElement {
  const isMobile = useIsMobile()

  return (
    <FooterContainer>
      <PaymentContainer
        alignItems="center"
        direction={{ md: 'row', xs: 'column' }}
        gap={{ xs: 1.5 }}
        isMobile={isMobile}
        justifyContent="space-between"
      >
        <SocialMediaLinks />
        <PaymentImage isMobile={isMobile} src={PaymentMethodsLogo} />
        <MT4Platform />
      </PaymentContainer>
      <Stack p={{ md: '40px 100px 0', xs: '28px 16px 16px 16px' }}>
        <RegulationCards />
        <Content />
        <Divider />
        <Copyright />
      </Stack>
      <ConsentBanner />
    </FooterContainer>
  )
}
