import React from 'react'

import { Trans } from '@lingui/macro'

import { muFlagIcon, vcFlagIcon } from '../../../../../Icon'
import fsaImage from './assets/logo_fsa.png'
import fscImage from './assets/logo_fsc.png'
import { RegulationCardProps } from './components'

export const REGULATION_CARDS: RegulationCardProps[] = [
  {
    country: 'Mauritius',
    flag: muFlagIcon,
    id: 'C208180',
    imageSrc: fscImage,
    title: <Trans>Financial Services Commission (FSC)</Trans>,
  },
  {
    country: 'St. Vincent and Grenadines',
    flag: vcFlagIcon,

    id: '2496 LLC 2022',
    imageSrc: fsaImage,
    title: <Trans>Financial Services Authority (FSA)</Trans>,
  },
]
