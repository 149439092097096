import React, { ForwardedRef, forwardRef, FunctionComponent, ReactElement, SVGProps } from 'react'

import { Icon as IconifyContainer, IconifyIcon } from '@iconify/react'
import { Box, BoxProps } from '@mui/material'

export type SVGComponent = FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>

export * from './tauro-icons'
export * from './social-media'
export * from './iconify-country-flags'

export type IconType = SVGComponent | IconifyIcon

export type IconProps = BoxProps & {
  icon: IconType
}

function IconBase(props: IconProps, ref: ForwardedRef<SVGSVGElement>): ReactElement {
  const { icon, ...rest } = props

  return isIconify(icon) ? (
    <Box component={IconifyContainer} icon={icon} ref={ref} {...rest} />
  ) : (
    <Box component={icon} ref={ref} {...rest} />
  )
}

function isIconify(icon: IconType): icon is IconType {
  return 'body' in icon
}

export default forwardRef(IconBase)
