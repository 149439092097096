import React, { FunctionComponent, ReactElement } from 'react'

import { Link } from '@mui/material'

import { Icon, IconContainer } from './styled'

export type SocialMediaLinkItemProps = {
  icon: FunctionComponent<React.SVGProps<SVGSVGElement>>
  linkTo: string
}

export function SocialMediaLinkItem(props: SocialMediaLinkItemProps): ReactElement {
  const { icon, linkTo } = props
  return (
    <Link href={linkTo}>
      <IconContainer>
        <Icon component={icon} />
      </IconContainer>
    </Link>
  )
}
