import React, { ReactElement } from 'react'

import { Stack } from '@mui/material'

import { RegulationCard } from './components'
import { REGULATION_CARDS } from './constants'

export function RegulationCards(): ReactElement {
  return (
    <Stack direction={{ md: 'row', xs: 'column' }} gap={3} pb={5} width="100%">
      {REGULATION_CARDS.map((card, index) => (
        <RegulationCard key={index} {...card} />
      ))}
    </Stack>
  )
}
