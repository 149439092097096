import React, { ReactElement, ReactNode } from 'react'

import { Box, Stack, Typography } from '@mui/material'

import Icon, { type IconType } from '../../../../../../Icon'
import { Paper, TextId } from './styles'

export type RegulationCardProps = {
  title: ReactNode
  imageSrc: string
  flag: IconType
  country: ReactNode
  id: string
}

export function RegulationCard(props: RegulationCardProps): ReactElement {
  const { country, flag, id, imageSrc, title } = props

  return (
    <Paper>
      <Box
        component="img"
        height={{ md: 40, xs: 36 }}
        src={imageSrc}
        sx={{ objectFit: 'contain', objectPosition: 'left' }}
      />

      <Stack>
        <Typography fontWeight={700} variant="body2">
          {title}
        </Typography>
        <TextId>{id}</TextId>
      </Stack>
      <Stack alignItems="center" direction="row" gap={1}>
        <Icon fontSize={'16px'} height={24} icon={flag} />
        <Typography variant="body2">{country}</Typography>
      </Stack>
    </Paper>
  )
}
