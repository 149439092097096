import { Box, Stack, styled } from '@mui/material'

export const Icon = styled(Box)(({ theme }) => ({
  height: '20px',
  margin: theme.spacing(2),
  width: '20px',
}))

export const IconContainer = styled(Stack)({
  '&:hover': {
    border: '1px solid #344054',
    color: '#344054',
  },

  alignItems: 'center',
  border: '1px solid #A4A7AE',
  borderRadius: '999px',
  color: '#A4A7AE',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})
