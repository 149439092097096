import React, { ReactElement } from 'react'

import { SocialMediaLinkItem } from './components/SocialMediaLinkItem'
import { SOCIAL_MEDIA_LINKS } from './constants'
import { Container } from './styled'

export function SocialMediaLinks(): ReactElement {
  return (
    <Container gap={{ xs: 1.5 }}>
      {SOCIAL_MEDIA_LINKS.map((socialMediaItem) => (
        <SocialMediaLinkItem key={socialMediaItem.linkTo} {...socialMediaItem} />
      ))}
    </Container>
  )
}
