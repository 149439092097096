import React from 'react'

import { Trans } from '@lingui/macro'
import { nanoid } from 'nanoid'

import { websiteRoutes } from '../../../../../../shared/constants'
import { NavItemProps } from '../Navtem'

type NavigationItemsProps = Pick<NavItemProps, 'to' | 'title' | 'subItems' | 'canBeActive'> & { id: string }

const EDUCATION_SUBITEMS: NavigationItemsProps[] = [
  {
    id: nanoid(),
    title: <Trans>Beginner Trading</Trans>,
    to: websiteRoutes.educationBeginner,
  },
  {
    id: nanoid(),
    title: <Trans>Intermediate Trading</Trans>,
    to: websiteRoutes.educationIntermediate,
  },
  {
    id: nanoid(),
    title: <Trans>Advanced Trading</Trans>,
    to: websiteRoutes.educationAdvanced,
  },
  {
    id: nanoid(),
    title: <Trans>Tools</Trans>,
    to: websiteRoutes.educationTools,
  },
  {
    id: nanoid(),
    title: <Trans>Daily Analysis</Trans>,
    to: websiteRoutes.educationDailyAnalysis,
  },
]

export const NAV_ITEMS: NavigationItemsProps[] = [
  {
    id: nanoid(),
    title: <Trans>Home</Trans>,
    to: websiteRoutes.home,
  },
  {
    id: nanoid(),
    subItems: EDUCATION_SUBITEMS,
    title: <Trans>Education</Trans>,
  },
  {
    canBeActive: false,
    id: nanoid(),
    title: <Trans>Account types</Trans>,
    to: websiteRoutes.accountTypes,
  },
  {
    id: nanoid(),
    title: <Trans>Partners</Trans>,
    to: websiteRoutes.partners,
  },
  {
    id: nanoid(),
    title: <Trans>Contact Us</Trans>,
    to: websiteRoutes.contacts,
  },
  {
    id: nanoid(),
    title: <Trans>About Us</Trans>,
    to: websiteRoutes.about,
  },
]
